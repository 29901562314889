const mainSlide = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if(pageTop){

    let jsMainSwiper = document.getElementById('jsMainSwiper');

    const swiper = new Swiper(jsMainSwiper, {
      speed: 1000,
      effect: "fade",
      loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
      pagination: {
        el: ".swiper-pagination"
      },
    });
  
  
  }
})();
export default mainSlide;