import {
  scrollControlFunc,
  windowResize,
  beside,
  parallax,
  karuizawaResize,
} from './index';

const loadFunc = (() => {
  let loadWrap = document.getElementById('loadWrap');
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no_act');
    scrollControlFunc.returnScroll();
    parallax()
    beside()
    // karuizawaResize()
  }

  window.addEventListener('load', function () {
    setTimeout(startFunc, 100)
  });

})();
export default loadFunc;