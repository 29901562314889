const karuizawaResize = () => {
  const pageKaruizawa = (document.getElementById("pageKaruizawa") != null);
  if (pageKaruizawa) {


    const resizeItems = document.querySelectorAll('.js-resize-item');


    let resizeItemsW = 0
    for (let i = 0; i < resizeItems.length; i++) {
      let resizeItemW = resizeItems[i].clientWidth;
      resizeItemsW = resizeItemsW + resizeItemW
    }


    const jsSideScrollList = document.getElementById('jsSideScrollList');

    console.log(resizeItemsW);
    jsSideScrollList.style.width = `${resizeItemsW}px`;




  }
}
export default karuizawaResize;