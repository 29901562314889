const contactThanks = (() => {

  document.addEventListener('wpcf7mailsent', () => {
    
    let jsContact = document.getElementById('jsContact');
    let firstChild = jsContact.firstElementChild;
    // console.log(formCntns);
    // console.log(firstChild);
    firstChild.remove();
    // document.getElementById('jsContact').innerHTML = '<div class="thanks"><p class="p1">お問い合わせを受け付けました。</p><p class="p2">ご記入頂いた情報は無事送信されました。<br>お問い合わせ頂き、ありがとうございました。</p></div>';
    jsContact.innerHTML = '<div class="thanks"><p class="p1">お問い合わせいただきありがとうございます。</p><p class="p2">後日担当者よりご連絡いたしますので、<br>恐れ入りますがしばらくお待ちください。</p></div>';
  }, false);

  
})();

export default contactThanks;