const parallax = () => {



  let sW = document.documentElement.clientWidth;

  let body = document.querySelector('body');

  if (body.classList.contains('tl') == true || body.classList.contains('sp') == true) {

    let rellax = new Rellax('.js-rellax', {
      // speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    });

  } else {

    const locoScroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      direction: 'horizontal',
      multiplier: 0.5,
      offset: ['30%', '0%'],
      horizontalGesture: true
    });
  
    window.addEventListener('resize', function () {
      locoScroll.update()
    });
  
  
    ScrollTrigger.refresh();
    ScrollTrigger.scrollerProxy(".side-scroll-list", {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
  
      pinType: document.querySelector(".side-scroll-list").style.transform
        ? "transform"
        : "fixed",
    });
  
  
    locoScroll.on('call', (target) => {
      // console.log(target);
      let targetBox = document.querySelector(`.${target}`);
      // console.log(targetBox);
      targetBox.classList.add("cover-open");
    });

  }



};
export default parallax;