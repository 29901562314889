const beside = () => {

  let sW = document.documentElement.clientWidth;

  let body = document.querySelector('body');

  if (body.classList.contains('tl') == true || body.classList.contains('sp') == true) {

  } else {

    const listWrapperEl = document.getElementById('jsSideScrollListWrapper');
    const listEl = document.getElementById('jsSideScrollList');

    gsap.to(listEl, {
      x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
      ease: 'none',
      scrollTrigger: {
        trigger: listWrapperEl,
        start: 'top top',
        end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        scroller: ".side-scroll-list",
      },
    });

  }

}
export default beside;