const karuizawaMain = (() => {
  const pageKaruizawa = (document.getElementById("pageKaruizawa") != null);
  if (pageKaruizawa) {

    const logo = document.getElementById('jsMainLogo');

    // console.log(logo);
    document.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        logo.classList.add('act')
      }, 1000);
    });

  }
})();
export default karuizawaMain;