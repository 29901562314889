const works = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    const worksBox = document.querySelectorAll('.js-works-box');
    const worksBtn = document.querySelectorAll('.js-works-btn');

    for (let i = 0; i < worksBtn.length; i++) {

      worksBtn[i].addEventListener('click', (event) => {
        console.log(i);
        for (let i = 0; i < worksBtn.length; i++) {
          worksBox[i].classList.remove('act');
        }
        worksBox[i].classList.add('act');
        // if( navBtn.classList.contains('act') == true ){
        //   nav.classList.remove('act');
        //   navBtn.classList.remove('act');
        // }else{
        //   nav.classList.add('act');
        //   navBtn.classList.add('act');
        // }
      });

    }


  }
})();
export default works;