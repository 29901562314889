const profileSlide = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let jsProfileSwiper = document.getElementById('jsProfileSwiper');
    let jsProfileNum = document.getElementById('jsProfileNum');

    const swiper = new Swiper(jsProfileSwiper, {
      speed: 1000,
      effect: "fade",
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: ".swiper-pagination"
      },
    });

    swiper.on('slideChangeTransitionStart', function () {
      // console.log(swiper.activeIndex);
      if(swiper.activeIndex % 2 == 0 ){
        jsProfileNum.textContent = '02';
      }else{
        jsProfileNum.textContent = '01';
      }
    });


  }
})();
export default profileSlide;