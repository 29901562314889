const form = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let jsInput = document.querySelectorAll('.form-field input, .form-field textarea');
    let jsLabel = document.querySelectorAll('.form-field label');


    console.log(jsInput);

    for (let i = 0; i < jsInput.length; i++) {
      // jsInput[i].addEventListener('keyup', (event) => {
      //   let value = jsInput[i].value;
      //   if(value){
      //     jsLabel[i].classList.add('up')
      //   } else{
      //     jsLabel[i].classList.remove('up')
      //   }
      // });

      jsInput[i].addEventListener('focus', (event) => {
        // console.log(event.target.parentNode.parentNode.querySelector('label'));
        // let label = event.target.parentNode.parentNode.querySelector('label')
        // label.classList.add('up')
        jsLabel[i].classList.add('up')
      }, true);

      jsInput[i].addEventListener('blur', (event) => {
        let value = jsInput[i].value;
        if (!value) {
          jsLabel[i].classList.remove('up')
        }
      }, true);
    }



  }

})();

export default form;