// import { workModalSlide } from './index';

const modal = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {


    MicroModal.init({
      onShow: modal => {
        console.log('onShow');
        slideRestart()
      },
      // onShow: modal => console.info(`${modal.id} is shown`),
      onClose: modal => {
        console.log('onClose');
      },
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    });


    let modalSwiper = document.querySelectorAll(".js-modal-swiper");
    let modalSwiperThumbs = document.querySelectorAll(".js-modal-swiper-thumbs");

    let swiperAry = []
    let swiperThumbsAry = []


    for (let i = 0; i < modalSwiper.length; i++) {

      let swiperThumbs
      let swiper

      swiperThumbs = new Swiper(modalSwiperThumbs[i], {
        lazy: true,
        slidesPerView: 22,
        observer: true,//タブ切り替え用
        observeParents: true,//タブ切り替え用
        // simulateTouch: false,
        // allowTouchMove: true,
      });
      
      swiper = new Swiper(modalSwiper[i], {
        lazy: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        speed: 1000,
        observer: true,//タブ切り替え用
        observeParents: true,//タブ切り替え用
        thumbs: {
          swiper: swiperThumbs
        },
        navigation: {
          nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
          prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
        },
        // loop: true,  // 無限ループさせる
        // loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
      });

      swiperAry.push(swiper);
      swiperThumbsAry.push(swiperThumbs);

    }


    const slideRestart = () => {
      for (let i = 0; i < swiperAry.length; i++) {
        swiperAry[i].slideTo(0)
        // swiperAry[i].slideToLoop(0)
        // swiperThumbsAry[i].slideTo(0)
        // console.log(i);
      }
    }


  }
})();
export default modal;