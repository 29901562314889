// const pageTop = (document.getElementById("bodyTop") != null);
export { default as userAgent } from './userAgent';
export { default as scrollControlFunc } from './scrollControlFunc';
export { default as windowResize } from './windowResize';
export { default as scrollFunc } from './scrollFunc';
// export { default as reload } from './reload';
export { default as navFunc } from './navFunc';
export { default as mainSlide } from './mainSlide';
export { default as profileSlide } from './profileSlide';
// export { default as workModalSlide } from './workModalSlide';
export { default as modal } from './modal';
export { default as works } from './works';
export { default as parallax } from './parallax';
export { default as beside } from './beside';
export { default as form } from './form';
export { default as contactThanks } from './contactThanks';

export { default as karuizawaMain } from './karuizawaMain';
export { default as karuizawaResize } from './karuizawaResize';

// export { default as loadImg } from './loadImg';
// export { default as loadImgEnd } from './loadImgEnd';

export { default as loadFunc } from './loadFunc';