const navFunc = (() => {

  let nav = document.getElementById('nav');
  let navBtn = document.getElementById('navBtn');
  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      nav.classList.add('none');
      navBtn.classList.remove('act');
    } else {
      nav.classList.remove('none');
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  });


  let navLink = nav.querySelectorAll('a');
  for (let i = 0; i < navLink.length; i++) {
    navLink[i].addEventListener('click', (event) => {
      nav.classList.remove('act');
      nav.classList.add('none');
      navBtn.classList.remove('act');
    });
  }


  const windowResize = () => {
    let sW = document.documentElement.clientWidth;
    let sH = document.documentElement.clientHeight;
    // let pageTop = document.getElementById('pageTop');
    let body = document.querySelector('body');

    if ((sW * 100) > (sH * 146)) {
      // pageTop.classList.remove('nav-act');
      body.classList.remove('nav-act');
    } else {
      // pageTop.classList.add('nav-act');
      body.classList.add('nav-act');
    }
  }
  windowResize();

  window.addEventListener("resize", (event) => {
    windowResize();
  });


})();
export default navFunc;